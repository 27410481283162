import axios from '@/api/axios';

export function getEmployeeReport(dateFrom, dateTo) {
  const result = axios.get(`/reports/printers/reports/employees?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data: { groupedByDates, groupedByGroups } }) => ({ groupedByDates, groupedByGroups }));
  return result;
}

export function getPrinterReport(dateFrom, dateTo) {
  const result = axios.get(`/reports/printers/reports/printers?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data: { groupedByDates, groupedByGroups } }) => ({ groupedByDates, groupedByGroups }));
  return result;
}
