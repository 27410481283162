<template>
  <table :class="$style.table">
    <thead>
      <tr :class="$style.header">
        <td
          v-for="column in columns"
          :key="column.name"
          @click="changeSort(column)"
        >
          <div v-if="column.field == 'name'" :class="$style.tdInline">
            {{ title.title }}
          </div>
          <div :class="$style.tdInline">
            {{ column.name }}
          </div>
          <img
            src="@/assets/images/icons/table/sort-arrow.svg"
            :class="[
              $style.tdInline,
              $style.arrow,
              {
                [$style.asc]: column.sort == 2,
                [$style.hidden]: column.sort == 0,
              },
            ]"
          >
        </td>
      </tr>
    </thead>
    <tbody v-for="department in sortedDeps" :key="department.name">
      <tr :class="$style.department">
        <td v-for="column in columns" :key="column.name">
          {{ department[column.field] }}
        </td>
      </tr>
      <tr
        v-for="item in department[title.element]"
        :key="item.name"
        :class="$style.item"
      >
        <td :class="$style.name">
          {{ item.name }}
        </td>
        <td v-for="column in (columns || []).slice(1)" :key="column.name">
          {{ item[column.field] }}
        </td>
      </tr>
    </tbody>
    <tr :class="$style.department">
      <td>Итого страниц</td>
      <td>{{ total.pages }}</td>
      <td>{{ total.cost }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    departments: {
      type: Array,
      required: true,
    },
    title: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    columns: [
      { name: '/ Сотрудник', sort: 0, field: 'name' },
      { name: 'Количество страниц', sort: 1, field: 'pages' },
      { name: 'Стоимость', sort: 0, field: 'cost' },
    ],
  }),
  computed: {
    sortedDeps() {
      const calc = this.departments.slice(0).sort(this.compare);
      calc.forEach((element) => element.employees.sort(this.compare));
      return calc;
    },
    total() {
      const total = { pages: 0, cost: 0 };
      this.departments.forEach((dep) => {
        total.pages += dep.pages;
        total.cost += dep.cost;
      });
      return total;
    },
  },
  methods: {
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;

      a = a[key];
      b = b[key];

      if (index === 0) {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.columns[index].sort === 1) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  width: 70%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
}

.header {
  background: #dbf1ff;
  height: 31px;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 4px 4px 20px;
    cursor: pointer;
  }
}

.department {
  background: $light-gray;
  height: 29px;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-size: 13px;
    line-height: 14px;
    padding: 7px 4px 7px 20px;
  }
}

.item {
  height: 29px;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-size: 14px;
    line-height: 14px;
    padding: 7px 4px 7px 20px;
    &.name {
      font-weight: 500;
      padding-left: 37px;
    }
  }
}
.tdInline {
  display: inline-flex;
  padding-right: 2px;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}
</style>
